import { FC, useEffect, useState } from "react";

import { Col, Row, Typography } from "antd";
const { Title } = Typography;

import ClientService from "../../../services/client.service";
import { ISummary } from "../../../interfaces/clients";

import "./teams-sharepoint-overview.scss";

const TeamsSharepointOverview: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ISummary | null>(null);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await ClientService.getSnapShotSummary();
    if (response?.status === 200) {
      setData(response.data);
    } else {
      // eslint-disable-next-line no-console
      console.log(">>> error");
    }
    setIsLoading(false);
  };

  return (
    <div className="teams-sharepoint-overview-section">
      <div className="app-container">
        <Title className="app-section-title">TEAMS/SHAREPOINT OVERVIEW</Title>
      </div>

      <div className="teams-sharepoint-overview-box">
        <div className="app-container">
          <Row>
            <Col lg={4} md={8} sm={8}>
              <Title level={2} className="teams-sharepoint-overview-box__title match-height">
                NUMBER OF
                <span>TEAMS</span>
              </Title>
              <Title level={5} className="teams-sharepoint-overview-box__value">
                {data?.totalTeams || 0}
              </Title>
            </Col>
            <Col lg={5} md={8} sm={8}>
              <Title level={2} className="teams-sharepoint-overview-box__title match-height">
                NUMBER OF
                <span>CHANNELS</span>
              </Title>
              <Title level={5} className="teams-sharepoint-overview-box__value">
                {data?.totalChannels || 0}
              </Title>
            </Col>
            <Col lg={5} md={8} sm={8}>
              <Title level={2} className="teams-sharepoint-overview-box__title match-height">
                NUMBER OF
                <span>SHAREPOINT SITES</span>
              </Title>
              <Title level={5} className="teams-sharepoint-overview-box__value">
                {data?.totalSharepointSites || 0}
              </Title>
            </Col>
            <Col lg={10} md={24}>
              <Title level={2} className="teams-sharepoint-overview-box__title match-height">
                NUMBER OF
                <span>FILES</span>
              </Title>
              <Row className="teams-sharepoint-overview-box__files-summary">
                <Col lg={24} md={12} xs={24}>
                  <div className="teams-sharepoint-overview-box__files-summary__item">
                    <Title level={5} className="teams-sharepoint-overview-box__value">
                      {data?.totalFiles || 0}
                    </Title>
                    <Title level={4} className="teams-sharepoint-overview-box__text">
                      Total Files
                    </Title>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="teams-sharepoint-overview-box__files-summary__item">
                    <Title level={5} className="teams-sharepoint-overview-box__value text-faded">
                      {data?.modifiedInLastThreeMonths || 0}
                    </Title>
                    <Title level={4} className="teams-sharepoint-overview-box__text text-faded">
                      Modified In Last 3 Months
                    </Title>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="teams-sharepoint-overview-box__files-summary__item">
                    <Title level={5} className="teams-sharepoint-overview-box__value text-faded">
                      {data?.modifiedInLastFourToTwelveMonths || 0}
                    </Title>
                    <Title level={4} className="teams-sharepoint-overview-box__text text-faded">
                      Modified In Last 4-12 Months
                    </Title>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="teams-sharepoint-overview-box__files-summary__item">
                    <Title level={5} className="teams-sharepoint-overview-box__value text-faded">
                      {data?.modifiedBeforeOneYear || 0}
                    </Title>
                    <Title level={4} className="teams-sharepoint-overview-box__text text-faded">
                      Modified More than 1 year Ago
                    </Title>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default TeamsSharepointOverview;
