import { MY_STORAGE } from "./constants";
import { IModule } from "../interfaces/common";
import MemberService from "../services/member.service";
import { IDreamTemplateNodes } from "../interfaces/dreamDepartment";

export const setInStorage = (key: string, value: string) => {
  MY_STORAGE.setItem(key, value);
};

export const getFromStorage = (key: string) => {
  return MY_STORAGE.getItem(key);
};

export const removeFromStorage = (key: string) => {
  return MY_STORAGE.removeItem(key);
};

export const clearStorage = () => {
  return MY_STORAGE.clear();
};

export const getActivityLogs = async () => {
  const response = await MemberService.getActivityLogs();
  if (response?.status === 200) {
    removeFromStorage("isFirstLogin");
    const thisData = response.data as IModule[];
    const thisDeptModule = thisData.find(t => t.module === 2);
    let isFirstLogin = false;
    if (!thisDeptModule) {
      isFirstLogin = true;
    } else {
      isFirstLogin = thisDeptModule.readCount === 0;
    }
    setInStorage("activity-logs", JSON.stringify(thisData));
    if (isFirstLogin) {
      setInStorage("isFirstLogin", "true");
    }
  }
};

export const sleep = (delay: number) => new Promise(resolve => setTimeout(resolve, delay));

export const getUrlParams = (key: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(key);
};

export const getInitials = (fullName: string | undefined) => {
  if (!fullName) {
    return "";
  }

  const allNames = fullName.trim().split(" ");
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, "");

  return initials;
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
};

export const validatePhoneNumber = (phoneNumberString: string): boolean => {
  if (phoneNumberString) {
    const check = phoneNumberString.match(/\d/g);
    if (check && check.length === 10) {
      return true;
    }
  }

  return false;
};

export const formatCurrency = (number: number | string, currency = "USD") => {
  if (!number || isNaN(Number(number))) number = 0;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(Number(number));
};

export const toTree = (data: IDreamTemplateNodes[], pid: number | null = null) => {
  return data.reduce((r: IDreamTemplateNodes[], e: IDreamTemplateNodes) => {
    if (e.parentId == pid) {
      const obj = { ...e };
      const children = toTree(data, e.id);
      if (children.length) obj.children = children;
      r.push(obj);
    }
    return r;
  }, []);
};
