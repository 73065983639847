import React, { useEffect, useRef } from "react";

const useDocumentTitle = (title: string, retainOnUnmount = false) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    let metaTitle = "";
    if (title) {
      metaTitle += `${title} - `;
    }
    metaTitle += process.env.REACT_APP_NAME || "IFP Client Portal";

    document.title = metaTitle;
  }, [title]);

  useEffect(() => {
    return () => {
      if (!retainOnUnmount) {
        document.title = defaultTitle.current;
      }
    };
  }, []);
};

export default useDocumentTitle;
