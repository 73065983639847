import API from "./axios";

class ClientService {
  recordConsent(tenantId: string, token: string) {
    const formData = {
      tenantId: tenantId,
      token: token,
    };
    return API.put(`/api/clientarea/Clients/recordConsent`, formData)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  getPhases = () => {
    return API.get("/api/clientarea/Audits/phases")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getSnapShotSummary = () => {
    return API.get("/api/clientarea/Snapshots/summary")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getSummary = () => {
    return API.get("/api/clientarea/Clients/summary")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getLatest = () => {
    return API.get("/api/clientarea/Audits/latest")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getTeamsData = () => {
    return API.get("/api/clientarea/Teams/lastAudit")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getSharePointData = () => {
    return API.get("api/clientarea/SharepointSites/lastAudit")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getFilesMetaData = () => {
    return API.get("/api/clientarea/Audits/files-metadata")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  downloadFile = (id: string) => {
    return API.get(`/api/clientarea/SharepointSites/download/${id}`, {
      responseType: "blob",
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getClients = () => {
    return API.get("/api/clientarea/Clients")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  countKeyFiles = () => {
    return API.get("/api/clientarea/SharepointSites/key-files-count")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  finalizeDepartments = () => {
    return API.post("/api/clientarea/Clients/finalize-departments")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };
}

export default new ClientService();
