import { FC } from "react";
import { Skeleton } from "antd";

interface ITableSkeleton {
  rowsCount: number;
  columnsCount: number;
}

const TableSkeleton: FC<ITableSkeleton> = ({ rowsCount, columnsCount }: ITableSkeleton) => {
  const renderTableColumns = () => {
    return (
      <>
        {[...Array(columnsCount)].map((column, index) => {
          return (
            <td key={`column-${index}`}>
              <Skeleton active paragraph={false} />
            </td>
          );
        })}
      </>
    );
  };

  return (
    <>
      {[...Array(rowsCount)].map((row, index) => {
        return <tr key={`row-${index}`}>{renderTableColumns()}</tr>;
      })}
    </>
  );
};

export default TableSkeleton;
