import API from "./axios";

class MemberService {
  getActivityLogs = () => {
    return API.get("/api/clientarea/Members/activity-logs")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };
}

export default new MemberService();
