import React, { FC, useEffect, useState } from "react";
import { format } from "date-fns";

import { Button, Typography } from "antd";
const { Title } = Typography;

import Loading from "../../components/Loading";

import { IPageProps } from "../../interfaces/common";
import { IClientPhases } from "../../interfaces/clients";
import ClientService from "../../services/client.service";
import useDocumentTitle from "../../hooks/useDocumentTitle";

import "./style.scss";

interface IFiles {
  id: string;
  name: string;
  lastActivityDateTime: string;
}

interface IKeyFiles {
  id: number;
  files: IFiles[];
}

const KeyFiles: FC<IPageProps> = (props: IPageProps) => {
  useDocumentTitle(props.pageMetaTitle);

  const [data, setData] = useState<IKeyFiles[]>([]);
  const [phases, setPhases] = useState<IClientPhases[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchPhases();
      await fetchData();
      setIsLoading(false);
    })();
  }, []);

  const fetchPhases = async () => {
    const response = await ClientService.getPhases();
    if (response?.status === 200) {
      setPhases(response.data);
    }
  };

  const fetchData = async () => {
    const response = await ClientService.getFilesMetaData();
    if (response?.status === 200) {
      const fileData = response.data;
      fileData.sort((a: IKeyFiles, b: IKeyFiles) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });
      setData(fileData);
    }
  };

  const renderContentItem = (keyFile: IKeyFiles) => {
    const phase = phases.find(p => p.id === keyFile.id);
    if (!phase) {
      return null;
    }

    return (
      <li key={keyFile.id}>
        <Title className="key-files-page__content__title">
          <img src={phase.iconUrl} />
          {phase.phaseName}
        </Title>
        <div className="key-files-page__content__documents">
          {keyFile.files.length ? (
            <table className="app-table">
              <tbody>{keyFile.files.map(renderTableBody)}</tbody>
            </table>
          ) : (
            <div className="no-data">no documents available</div>
          )}
        </div>
      </li>
    );
  };

  const downloadFile = async (file: IFiles) => {
    const res = await ClientService.downloadFile(file.id);
    if (res?.status === 200) {
      const reader = new window.FileReader();
      reader.onload = e => {
        const downloadLink = document.createElement("a");
        downloadLink.href = e.target?.result as string;
        downloadLink.download = file.name;
        downloadLink.click();
      };
      reader.readAsDataURL(res.data);
    }
  };

  const renderTableBody = (file: IFiles) => {
    const date = format(new Date(file.lastActivityDateTime), "MM/dd/yyyy");
    return (
      <tr key={file.id}>
        <td>{file.name}</td>
        <td width={100}>{date}</td>
        <td width={100}>
          <Button className="btn-download" onClick={() => downloadFile(file)}>
            DOWNLOAD
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <div className="key-files-page">
      <div className="app-container">
        <Title className="key-files-page__title">{props.pageTitle}</Title>

        {isLoading ? (
          <Loading message="Fetching Files..." />
        ) : (
          <ul className="key-files-page__content">{data.map(renderContentItem)}</ul>
        )}
      </div>
    </div>
  );
};

export default KeyFiles;
