export const API_PREFIX = process.env.REACT_APP_API_BASE_URL;
export const MY_STORAGE = window.sessionStorage;

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

// Urls
export const URL = {
  DEMO: "/demo",
  DASHBOARD: "/",
  LOGIN: "/login",
  NO_ACCESS: "/403",
  KEY_FILES: "/key-files",
  RECORD_CONSENT: "/record-consent",
  DREAM_DEPARTMENTS: "/structures",
  DREAM_TEMPLATES: "/structures/:id/templates",

  IFRAME_ROI: "/iframe/roi-calculator",
};

export const VALIDATE_MESSAGES = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
